<template>
    <b-container fluid>
        <b-row>
            <Breadcrumb titulo="TEMPLATES DE CAMPANHAS - WHATSAPP CONVENCIONAL"
                        :items="breadcrumb"/>
        </b-row>
        <b-row class="mx-0 my-3">
            <b-col class="templates-container p-3">
                <div class="d-flex flex-row-reverse mb-3">
                    <b-button class="rounded-0 button-novo-disparo border-0" @click="criarNovoTemplate">Novo Template</b-button>
                </div>
                <div class="bg-white p-2" style="font-size: 12px; height: calc(100vh - 260px); overflow-y: auto;">
                    <b-table striped hover :busy="loading" :fields="fields" :items="items" class="my-0">
                        <template #cell(Mensagem)="data">
                            <span v-html="data.item.Mensagem" data-toggle="tooltip" data-placement="top" :title="data.item.Mensagem.replace(/\<(br)\/\>/gi, '\n').trim()"></span>
                        </template>

                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(Tipo)="data">
                            <span class="text-nowrap">
                                <i :class="`fas ${templateTypes[data.value.toUpperCase()].icon}`"></i>
                                &nbsp;
                                {{templateTypes[data.value.toUpperCase()].label}}
                            </span>
                        </template>

                        <template #cell(actions)="data">
                            <i class="fas fa-pen template-send mx-2" style="cursor: pointer;" title="Editar template..." @click="Editar(data.item)"></i>
                            <i class="fas fa-trash template-send mx-2" style="cursor: pointer;" title="Excluir template..." @click="Excluir(data.item.Id, data.item.Assunto)"></i>
                            <i class="fas fa-paper-plane template-send mx-2" style="cursor: pointer;" title="Disparar template..." @click="enviar(data.item)"></i>
                        </template>
                    </b-table>
                </div>
            </b-col>
        </b-row>
        <b-modal ref="modalCriarNovoDiasparo"
                 title="Criar Novo Template"
                 v-model="modalShow">

            <form ref="form">
                <b-row class="styles-inputs">
                    <b-col>
                        <b-form-group invalid-feedback="Assunto obrigatório">

                            <b-form-input required
                                          type="text"
                                          placeholder="Assunto"
                                          v-model="assunto"
                                          style="height: calc(1.5em + 0.75rem + 0px);"
                                          class="rounded-0 form--control size-font-class" />
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-select v-model="tipoMensagemSelecionado"
                                       style="height: calc(1.5em + 0.75rem + 0px);"
                                       class="rounded-0 size-font-class"
                                       required>

                            <template #first>
                                <b-form-select-option :value="''" disabled>Selecione</b-form-select-option>
                            </template>

                            <b-form-select-option v-for="select in listSelects.typeMessage" :value="select.type">
                                {{select.name}}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
                <b-row v-if="tipoMensagemSelecionado == 'Button'">
                    <b-col>
                        <div class="styles-inputs">
                            <b-form-input type="text"
                                          placeholder="Titulo"
                                          v-model="tituloButtons"
                                          style="height: calc(1.5em + 0.75rem + 0px);"
                                          class="rounded-0 form-control size-font-class" />
                        </div>
                    </b-col>
                </b-row>
                <div class="container mx-0 mx-0 my-3">
                    <b-row>

                        <div class="d-flex justify-items-center p-1 rounded w-100 styles-inputs whatsweb-message-box">
                            <svg viewBox="0 0 8 13" width="8" height="13">
                                <path opacity=".13" d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"></path>
                                <path fill="#d9fdd3" d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"></path>
                            </svg>
                            <b-form-textarea v-model="MensagemDisparo"
                                             :rows="row"
                                             class="w-100 border-0 rounded-0 size-font-class"
                                             required></b-form-textarea>
                        </div>
                    </b-row>
                    <b-row>
                        <span class="legenda px-2 mt-1">{{LegendaAjuda}}</span>
                    </b-row>

                </div>

                <div class="styles-inputs" v-if="tipoMensagemSelecionado == 'Button'">
                    <div class="box-chat-button">
                        <b-row class="my-2">
                            <b-col>
                                <b-form-input type="text"
                                              placeholder="Rodapé"
                                              v-model="footerButtons"
                                              style="height: calc(1.5em + 0.75rem + 0px);"
                                              class="rounded-0 form-control size-font-class" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-4" v-for="(button, index) in getButtons" :key="button.id">
                            <b-col>
                                <b-form-input type="text"
                                              :placeholder="`${button.label} ${button.id}`"
                                              v-model="button.text"
                                              style="height: calc(1.5em + 0.75rem + 0px);"
                                              class="rounded-0 form-control size-font-class" />
                            </b-col>
                            <b-col>
                                <b-form-select v-model="button.type"
                                               style="height: calc(1.5em + 0.75rem + 0px);"
                                               class="rounded-0 size-font-class">

                                    <b-form-select-option v-for="tipo in button.tiposButton" :value="tipo.value">
                                        {{tipo.text}}
                                    </b-form-select-option>
                                </b-form-select>
                            </b-col>
                            <b-col v-if="button.type">
                                <b-form-input type="text"
                                              :placeholder="button.type == 'url' ? 'Link' : 'Telefone'"
                                              v-model="button.valorTipoBotaoSelecionado"
                                              style="height: calc(1.5em + 0.75rem + 0px);"
                                              class="rounded-0 form-control size-font-class" />
                            </b-col>
                            <div v-if="buttons.length > 1" class="remove-button-style">
                                <span class="fa fa-trash align-bottom" @click="deleteButton(button.id)" style="cursor: pointer;" />
                            </div>
                        </b-row>
                        <div class="d-flex justify-content-center pt-3">
                            <b-button variant="outline-secondary" v-if="buttons.length < 3" style="font-size: 12px;" @click="addNewButton">
                                <i class="fa fa-plus" /><span> Novo Botão</span>
                            </b-button>
                        </div>
                    </div>
                </div>

            </form>

            <template #modal-footer="{ ok, cancel}">
                <div class="w-100">
                    <b-button variant="success"
                              class="float-right mx-2 rounded-0"
                              :disabled="isDadosNaoPreenchidos"
                              @click="Salvar(ok)">
                        Salvar
                    </b-button>
                    <b-button variant="white"
                              class="float-right mx-2"
                              @click="Cancelar(cancel)">
                        Cancelar
                    </b-button>
                </div>
            </template>

        </b-modal>
        <ModalTransmissaoCampanha ref="modal-transmissao" />
        <ModalSelecaoDestinatarios :mandatoryFields="['Telefone']" ref="modal-selecao-destinatarios" />
    </b-container>
</template>

<script>
    import axios from 'axios';
    import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
    import ModalTransmissaoCampanha from "@/components/campanhas/ModalTransmissaoCampanha";
    import ModalSelecaoDestinatarios from "@/components/campanhas/ModalSelecaoDestinatarios";

    export default {
        components: {
            Breadcrumb,
            ModalTransmissaoCampanha,
            ModalSelecaoDestinatarios
        },
        computed: {
            LegendaAjuda: function () {
                return "Para criar variações, utilize o comando {{'numero'}}, não repetindo o número final. "
                        + "Elas serão definidas no momento do disparo"
            },
            getButtons () {
                return this.buttons
            },
            isDadosNaoPreenchidos() {
                if (this.MensagemDisparo) {
                    if (this.tipoMensagemSelecionado != 'Button') {
                        return (this.tipoMensagemSelecionado && this.assunto) ? false : true
                    }
                    else {
                        return this.buttons.map(button => {
                            if (button.text && button.type == null) {
                                return false
                            }
                            return (button?.text && button.valorTipoBotaoSelecionado) ? false : true
                        }).includes(true)
                    }
                }
                return true
            }
        },
        data() {
            return {
                breadcrumb: [
                    {
                        id: "1",
                        classe: "fas fa-cogs",
                        texto: "Cadastros Básicos",
                        link: "/cadastros-basicos",
                        cor: "#259cd3"
                    },
                    {
                        id: "2",
                        classe: "fa fa-bullhorn",
                        texto: "Campanhas",
                        link: "/campanhas",
                        cor: "#259cd3"
                    },
                    {
                        id: "3",
                        classe: "fab fa-whatsapp",
                        texto: "WhatsApp convencional",
                        link: "/campanhas-whatsapp-convencional",
                        cor: "#259cd3"
                    }
                ],
                templateTypes: {
                    TEXT: {
                        icon: "fa-align-left",
                        label: "Texto"
                    },
                    HTML: {
                        icon: 'fa-html5',
                        label: 'Html'
                    },
                    IMAGE: {
                        icon: "fa-image",
                        label: "Imagem"
                    },
                    VIDEO: {
                        icon: "fa-video",
                        label: "Video"
                    },
                    DOCUMENT: {
                        icon: "fa-file-alt",
                        label: "Documento"
                    },
                    LOCATION: {
                        icon: "fa-location-arrow",
                        label: "Localização"
                    },
                    BUTTON: {
                        icon: "fa-link",
                        label: "Botão"
                    }
                },
                fields: [
                    { key: 'Assunto', label: 'Assunto', sortable: true, class: 'align-middle' },
                    { key: 'Tipo', label: 'Tipo', sortable: true, class: 'align-middle' },
                    { key: 'Mensagem', label: 'Conteúdo', class: 'w-50' },
                    { key: 'actions', label: '', class: 'align-middle text-center'}
                ],
                listSelects: {
                    typeMessage: [
                        { name: 'Texto', type: 'Text' },
                        { name: 'Imagem', type: 'Image' },
                        { name: 'Video', type: 'Video' },
                        { name: 'Documento', type: 'Document' },
                        { name: 'Botão', type: 'Button' }
                    ]
                },
                items: [],
                modalShow: false,
                tipoMensagemSelecionado: '',
                MensagemDisparo: '',
                assunto: '',
                row: 1,
                loading: false,
                isEdit: false,
                dataId: '',
                setoresDisponiveis: null,
                tituloButtons: null,
                footerButtons: null,

                buttons: [
                    {
                        label: `Botão`, id: "1", text: "", tiposButton: [
                            {
                                text: 'Normal', value: null
                            },
                            {
                                text: 'Link', value: 'url'
                            },
                            {
                                text: 'Telefone', value: 'phoneNumber'
                            }
                        ],
                        type: null,
                        valorTipoBotaoSelecionado: null
                    }
                ],
            };
        },
        watch: {
            MensagemDisparo() {
                if (this.MensagemDisparo)
                    this.row = this.MensagemDisparo.split('\n').length;
            }
        },
        created() {
            this.BuscaCampanhaTemplates();
            axios.get("/api/campanhas/setores-disponiveis/9").then(response => {
                this.setoresDisponiveis = response.data;
            });
        },
        methods: {
            async criarNovoTemplate() {
                this.reset();
                this.isEdit = false;
                this.modalShow = true;
            },
            async BuscaCampanhaTemplates() {
                this.loading = true;
                axios.get('/api/campanhas/BuscaCampanhaTemplates/9').then(res => {
                    this.items = res.data
                    this.loading = false;
                }).catch(err => {
                    console.log("Erro:", err)
                    this.loading = false;
                });

            },
            async Salvar(ok) {
                var re = new RegExp('\n', 'gi')

                var buttonsLocal = this.buttons.map(button => { return { id: button.id, text: button.text } } )

                this.buttons.forEach((button, index) => {
                    if (button.type) { buttonsLocal[index][button.type == 'url' ? 'url' : 'phoneNumber'] = button.valorTipoBotaoSelecionado }
                })

                var data = {
                    Assunto: this.assunto,
                    Tipo: this.tipoMensagemSelecionado != '' ? this.tipoMensagemSelecionado : null,
                    Mensagem: this.MensagemDisparo.replace(re, '<br/>'),
                    BotaoChat: {
                        Titulo: this.tituloButtons,
                        Rodape: this.footerButtons
                    },
                    Botoes: this.tipoMensagemSelecionado.toUpperCase() == 'BUTTON' ? [...buttonsLocal] : null
                }

                if (!this.isEdit) {
                    axios.post('/api/SharkWhats/SalvarCampanhaTemplates', data, { headers: { 'Content-Type': 'application/json' } })
                        .then(res => {
                            this.items = res.data
                        }).catch(err => {
                            console.log('Erro', err);
                        });
                } else {
                    axios.put(`/api/SharkWhats/EditarCampanhaTemplates/${this.dataId}`, data, { headers: { 'Content-Type': 'application/json' } })
                        .then(res => {
                            this.items = res.data
                        }).catch(err => {
                            console.log('Erro', err);
                        });

                    this.dataId = ''
                }
                this.isEdit = false;
                this.reset()
                ok()
            },

            async Editar(data) {
                
                if (data.Tipo == 'Button') {
                    this.buttons.splice(0, this.buttons.length, ...data.Botoes.map(button => {
                        return {
                            label: `Botão`, tiposButton: [
                                {
                                    text: 'Normal', value: null
                                },
                                {
                                    text: 'Link', value: 'url'
                                },
                                {
                                    text: 'Telefone', value: 'phoneNumber'
                                }
                            ],
                            id: button.id,
                            text: button.text,
                            type: button.url ? 'url' : (button.phoneNumber ? 'phoneNumber' : null),
                            valorTipoBotaoSelecionado: button[button.url ? 'url' : (button.phoneNumber ? 'phoneNumber' : null)] ?? null
                        }
                    }));
                }

                this.dataId = data.Id;
                this.assunto = data.Assunto;
                this.MensagemDisparo = data.Mensagem.replace(/<(br)+\/>/gi, '\n');
                this.tipoMensagemSelecionado = data.Tipo;
                this.tituloButtons = data.Titulo;
                this.footerButtons = data.Rodape;
                this.isEdit = true;
                this.modalShow = true;
            },
            async Excluir(id, assunto) {
                axios.delete(`/api/SharkWhats/DeleteCampanhaTemplates/${id}`)
                    .then(res => {
                        this.$bvToast.toast(res.data, {
                            title: `${assunto} Deletado`,
                            variant: 'success',
                            solid: true
                        })
                        this.BuscaCampanhaTemplates();
                    }).catch(err => {
                        console.log('Erro', err);
                    });
            },

            async Cancelar(cancel) {
                cancel()
            },

            reset() {
                this.dataId = ''
                this.assunto = null
                this.tipoMensagemSelecionado = ''
                this.MensagemDisparo = null
                this.tituloButtons = null
                this.footerButtons = null
                this.buttons = [
                    {
                        label: `Botão`, id: "1", text: "", tiposButton: [
                            {
                                text: 'Normal', value: null
                            },
                            {
                                text: 'Link', value: 'url'
                            },
                            {
                                text: 'Telefone', value: 'phoneNumber'
                            }
                        ],
                        type: null,
                        valorTipoBotaoSelecionado: null
                    }
                ]
            },

            enviar(template) {
                if (!this.setoresDisponiveis || this.setoresDisponiveis.length == 0) {
                    this.$bvModal.msgBoxOk("Não existe nenhum setor habilitado para o envio de mensagens via WhatsApp Business.", {
                        title: "Transmissão indisponível",
                        okVariant: "danger"
                    });
                    return;
                }
                if (template.Botoes != null) {
                    template.Botoes.map(button => { return { id: button.id, text: button.text, type: button.type } })
                    template.Botoes.forEach((button, index) => {
                        delete template.Botoes[index].type;
                    })
                }
                this.$refs["modal-selecao-destinatarios"].select().then(selecao => {
                    this.$refs["modal-transmissao"].open({
                        title: template.Assunto,
                        type: template.Tipo.toUpperCase(),
                        content: template.Mensagem,
                        additionalInfo: {
                            titleButton: template.Titulo,
                            footerButton: template.Rodape,
                            botoes: JSON.stringify(template.Botoes)
                        }
                        
                    }, selecao, 9);
                });
            },
            addNewButton() {
                this.buttons.push({
                    label: `Botão`, tiposButton: [
                        {
                            text: 'Normal', value: null
                        },
                        {
                            text: 'Link', value: 'url'
                        },
                        {
                            text: 'Telefone', value: 'phoneNumber'
                        }
                    ],
                    id: (this.buttons.length + 1).toString(),
                    text: null,
                    type: null,
                    valorTipoBotaoSelecionado: null
                });
            },
            deleteButton(buttonId) {
                this.buttons.splice(this.buttons.indexOf(this.buttons.find(button => button.id == buttonId)), 1)
                this.buttons.forEach((button, index) => button.id = (index + 1).toString())
            },
            changeTypeButton(buttonId) {
                this.buttons.find(button => button.id == buttonId).valorTipoBotaoSelecionado = null
            }
        }
    }
</script>

<style scoped>
    .breadcrumb {
        background-image: linear-gradient(to bottom, #45ed63, #27ca44);
    }

    .templates-container {
        background-color: var(--cinza-2);
        margin-bottom: 70px;
        min-height: calc(100vh - 170px);
    }

        .templates-container > table {
            background-color: #fff;
            overflow-y: auto;
            margin-bottom: 0;
            font-size: 12px;
            padding: 0 7px 7px 7px;
            margin: 8px 0;
            cursor: default;
        }

    .styles-inputs input, select {
        background-color: var(--cinza-2) !important;
        font-size: 15px;
        padding: 5px 7px;
        outline-style: none;
        transition: all .3s ease-in-out;
        width: 100%;
    }

    .styles-inputs textarea {
        resize: none;
        background-color: transparent;
    }

    .whatsweb-message-box {
        background-color: #d9fdd3;
        position: relative;
        box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
    }

        .whatsweb-message-box > svg {
            position: absolute;
            top: 0;
            right: -8px;
        }

    .button-novo-disparo {
        font-size: 13px;
        height: 35px;
        background-color: var(--cor-primaria-cliente);
    }

    .legenda {
        font-size: 8px;
        color: #a1a1a1;
    }

    .template-send {
        font-size: 13px;
        cursor: pointer;
        transition: color ease-in-out .3s;
    }

        .template-send:hover {
            color: var(--cor-primaria-cliente);
        }

    .box-chat-button {
        width: 100%;
        height: auto;
    }

    .remove-button-style {
        margin-right: 3%;
    }

    .size-font-class {
        font-size: 12px !important;
    }
</style>